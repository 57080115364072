<template>
  <div v-if="'success' === status && data?.length > 0" class="offer-modal">
    <div class="offer-modal__wrp">
      <button
        class="offer-modal__close"
        @click.prevent="adTimingStore.setAdShown(true)"
      >
        <img src="~~/assets/icons/cross-white.svg" alt="close" />
      </button>
      <Swiper
        :slides-per-view="1"
        :loop="true"
        :autoplay="{
          delay: AUTOPLAY_TIME,
          disableOnInteraction: false,
        }"
        :simulate-touch="false"
        :modules="[SwiperAutoplay]"
        class="offer-modal__slider"
        @autoplay-time-left="onAutoplayTimeLeft"
      >
        <SwiperSlide v-for="card in data" :key="card.id">
          <AdPopupCard :card="card" />
        </SwiperSlide>
        <div
          class="offer-modal__progress-bar"
          :style="{ '--width': progressBar + '%' }"
        />
      </Swiper>
    </div>
  </div>
</template>

<script setup>
import AdPopupCard from "~/modules/shared/adPopup/AdPopupCard.vue";
import { useAdTimingStore } from "~/store/adTimingStore";
import { useAsyncPrivateAPI } from "~/uses/useMyFetch";

const adTimingStore = useAdTimingStore();

const { data, status } = await useAsyncPrivateAPI("/entries", {
  params: {
    content_type: useRuntimeConfig().public.contentful.contentType.popUpAd,
  },
});

const AUTOPLAY_TIME = 10000;
const progressBar = ref(0);

function onAutoplayTimeLeft(swiper, timeLeft) {
  progressBar.value = Math.round(
    ((AUTOPLAY_TIME - timeLeft) / AUTOPLAY_TIME) * 100,
  );
}
</script>

<style lang="scss">
.offer-modal {
  max-width: 400px;
  width: 100%;
  height: 300px;

  position: fixed;
  left: 16px;
  bottom: 16px;
  z-index: 509;

  @include bigMobile {
    max-width: 260px;
    height: 196px;
  }

  &__wrp {
    height: 100%;
  }

  &__close {
    @include fixedHW(34px, 34px);

    position: absolute;
    top: -17px;
    right: -17px;
    z-index: 510;

    @include flex-container(column, center, center);

    background-color: var(--color-ink-base);

    border-radius: 50%;
  }

  &__progress-bar {
    --width: 0;

    width: var(--width);
    height: 4px;

    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 2;

    background: var(--color-primary-yellow);

    transition: width 0.1s linear;

    @include bigMobile {
      height: 2px;
    }
  }

  &__slider {
    height: 100%;

    border-radius: 16px;

    .swiper-scrollbar {
      top: auto;
      bottom: 0;

      .swiper-scrollbar-drag {
        background: var(--color-primary-yellow);
      }
    }
  }
}
</style>
