import { useLanguageFilterStore } from "~/store/languageFilterStore";
import { useI18nStore } from "~/store/i18nStore";

export const getLocalizedName = (localizedValues) => {
  const languageStore = useLanguageFilterStore();
  const { getUserLanguage: lang } = storeToRefs(languageStore);

  return (
    localizedValues[lang.value.name] ??
    Object.values(localizedValues).find((value) => null !== value)
  );
};

export const getLocalizedRouteName = (routeName) => {
  const languageStore = useLanguageFilterStore();
  const { getLocalizedRoute } = storeToRefs(languageStore);

  return getLocalizedRoute.value(routeName);
};

export const _T = (key) => {
  return useI18nStore().getTranslation(key);
};
