import { useAPI } from "~/uses/useMyFetch";

export default defineNuxtRouteMiddleware(async (to) => {
  if (import.meta.server) {
    const config = useRuntimeConfig();

    const redirectPath = await useAPI(
      config.public.apiBaseUrl + "/user/shared/redirect",
      {
        params: {
          path: to.path,
        },
      },
    );

    if (redirectPath.data !== null) {
      return navigateTo(redirectPath.data?.path);
    }
  }

  if (parseInt(to.query.minPrice || 0) > parseInt(to.query.maxPrice || 0)) {
    return navigateTo(to.path);
  }

  if (to.name.replace(/^[a-z]+_/, "") === "account") {
    return navigateTo("/account/profile", { replace: true });
  }
});
