export const useUtm = (query) => {
  const parameters = {};
  const parameterKeys = ["source", "medium", "campaign", "term", "content"];

  for (const parameterKey of parameterKeys) {
    const queryValue = query["utm_" + parameterKey];

    if ("string" === typeof queryValue && 0 !== queryValue.length) {
      parameters[parameterKey] = queryValue;
    }
  }

  return {
    parameters,
    isExists: 0 !== Object.keys(parameters).length,
  };
};
