<template>
  <section class="engineering-works">
    <div class="engineering-works__wrp">
      <div class="engineering-works__content">
        <h3 class="engineering-works__title">Ведуться технічні роботи</h3>

        <div class="engineering-works__clue">
          <p class="engineering-works__text">
            На даний момент сайт недоступний, просимо вибачення
          </p>
          <p v-if="null !== getMessage" class="engineering-works__text">
            {{ getMessage }}
          </p>
          <p class="engineering-works__text">
            Для придбання товару або консультації можна звертатися за номером
            телефону:
          </p>
        </div>

        <a class="engineering-works__btn" href="tel:0800218573">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              class="engineering-works__btn-icon"
              d="M18.1183 14.702L14.0001 15.5C11.2184 14.1038 9.50009 12.5 8.50009 10L9.27004 5.8699L7.81461 2L4.06369 2C2.93614 2 2.04823 2.93178 2.21663 4.04668C2.63704 6.83 3.87662 11.8765 7.50009 15.5C11.3053 19.3052 16.7858 20.9564 19.8021 21.6127C20.9669 21.8662 22.0001 20.9575 22.0001 19.7655L22.0001 16.1812L18.1183 14.702Z"
              stroke="#F36C21"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <span>0 800 218 573</span>
        </a>

        <div class="engineering-works__social">
          <p class="engineering-works__social-title">Нашi соцiальнi мережi</p>
          <div class="engineering-works__social-networks">
            <a
              v-for="social in socials"
              :href="social.link"
              class="engineering-works__social-item"
              target="_blank"
            >
              <img :src="social.iconBlack" :alt="social.alt" />
            </a>
          </div>
        </div>
      </div>

      <div class="engineering-works__img-wrp">
        <img src="/img/engineering.png" alt="cat" />
        <h3 class="engineering-works__img-title">
          Ми маємо технічні роботи, але вже скоро ми будемо з вами
        </h3>
      </div>
    </div>
  </section>
</template>

<script setup>
import { useMaintenanceStore } from "~~/store/maintenanceStore";
import socials from "~/config/social.config";

definePageMeta({
  layout: false,
});

const maintenanceStore = useMaintenanceStore();
const { getIsEnabled, getMessage } = storeToRefs(maintenanceStore);

const interval = setInterval(async () => {
  maintenanceStore.fetch();

  watch(getIsEnabled, () => {
    if (!getIsEnabled.value) {
      clearInterval(interval);

      window.location.reload();
    }
  });
}, 5000);
</script>

<style lang="scss" scoped>
.engineering-works {
  padding: 64px 16px 100px;

  @include bigMobile {
    background-color: white;

    padding: 24px 16px;
  }

  &__wrp {
    @extend %width-main;

    @include flex-container(row, space-between, center);

    gap: 24px;
    margin: 0 auto;

    @include bigMobile {
      @include flex-container(column-reverse, space-between, center);
    }
  }

  &__content {
    max-width: 468px;
    width: 100%;

    @include flex-container(column, space-between, center);
    gap: 24px;

    @include bigMobile {
      max-width: 100%;
      text-align: center;

      gap: 16px;
    }
  }

  &__title {
    @include font(24, 34, 700);
    color: #2b2b2b;
    letter-spacing: 0.02em;

    @include bigMobile {
      @include font(20, 28, 700);
    }
  }

  &__clue {
    @include flex-container(column, space-between);

    gap: 8px;

    @include bigMobile {
      gap: 16px;
    }
  }

  &__text {
    @include font();
    color: #2b2b2b;
    letter-spacing: 0.02em;
  }

  &__btn {
    width: max-content;

    @include flex-container(row, space-evenly, center);
    gap: 16px;

    border: 1px solid var(--color-primary-base);
    background-color: var(--color-primary-base);
    border-radius: 8px;

    padding: 8px 32px;

    transition:
      border-color 0.1s,
      background-color 0.1s;

    @include mobile {
      width: 100%;

      @include flex-container(row, center, center);
    }

    &:hover {
      border-color: #ff5b00;
      background-color: #ff5b00;

      & span {
        color: white;
      }

      .engineering-works__btn-icon {
        stroke: white;
      }
    }

    &:active {
      border-color: #d8570f;
      background-color: #d8570f;

      & span {
        color: white;
      }

      .engineering-works__btn-icon {
        stroke: white;
      }
    }

    & span {
      @include font(18, 24);
      letter-spacing: 0.02em;

      color: white;

      transition: color 0.1s ease;
    }

    .engineering-works__btn-icon {
      stroke: white;
    }
  }

  &__social {
    @include flex-container(column, space-between, center);

    gap: 16px;

    @include bigMobile {
      gap: 8px;
    }
  }

  &__social-title {
    @include font(18, 24, 500);
    letter-spacing: 0.02em;

    @include bigMobile {
      color: var(--color-primary-base);
    }
  }

  &__social-networks {
    max-width: 168px;
    width: 100%;

    @include flex-container(row, space-between, center);

    gap: 8px;

    @include bigMobile {
      max-width: 100%;

      justify-content: space-around;
    }
  }

  &__social-item {
    font-size: 0;
  }

  &__img-wrp {
    max-width: 654px;
    width: 100%;

    @include flex-container(column, null);

    text-align: center;

    gap: 8px;

    @include bigMobile {
      max-width: 420px;
    }

    @include mobile {
      max-width: 220px;
    }
  }

  &__img-title {
    @include font(36, 44, 700);
    color: #2b2b2b;
    letter-spacing: 0.02em;

    @include bigMobile {
      @include font(16, 24, 700);
    }

    @include mobile {
      @include font(12, 16, 700);
    }
  }
}
</style>
