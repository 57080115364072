export default [
  {
    alt: "youtube",
    link: "https://www.youtube.com/c/LogicfoxLogicpower",
    iconBlack: "/img/youtube-black.svg",
    iconWhite: "/img/youtube-white.svg",
    iconOrange: "/img/youtube-orange.svg",
  },
  {
    alt: "instagram",
    link: "https://www.instagram.com/logicpower_official/",
    iconBlack: "/img/instagram-black.svg",
    iconWhite: "/img/instagram-white.svg",
    iconOrange: "/img/instagram-orange.svg",
  },
  {
    alt: "facebook",
    link: "https://www.facebook.com/logicpower.official",
    iconBlack: "/img/facebook-black.svg",
    iconWhite: "/img/facebook-white.svg",
    iconOrange: "/img/facebook-orange.svg",
  },
  {
    alt: "telegram",
    link: "https://t.me/LogicPowerBlog",
    iconBlack: "/img/telegram-black.svg",
    iconWhite: "/img/telegram-white.svg",
    iconOrange: "/img/telegram-orange.svg",
  },
];
