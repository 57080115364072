export const accountModal = "accountModal";
export const preOrderModal = "preOrderModal";
export const successfulModal = "successfulModal";
export const unsuccessfulModal = "unsuccessfulModal";
export const setNewPasswordModal = "setNewPasswordModal";
export const autonomicSubscriptionModal = "autonomicSubscriptionModal";
export const cartModal = "cartModal";
export const reviewModal = "reviewModal";
export const landingPageModal = "landingPageModal";
export const sppModal = "sppModal";
export const getContactModal = "getContactModal";

export const modals = {
  cartModal: defineAsyncComponent(
    () => import("~/modules/cart/components/Cart.vue"),
  ),
  preOrderModal: defineAsyncComponent(
    () => import("~/modules/shared/modals/PreOrderModal.vue"),
  ),
  successfulModal: defineAsyncComponent(
    () => import("~/modules/shared/modals/components/SuccessfulModal.vue"),
  ),
  unsuccessfulModal: defineAsyncComponent(
    () => import("~/modules/shared/modals/components/UnsuccessfulModal.vue"),
  ),
  accountModal: defineAsyncComponent(
    () => import("~/modules/account/components/sign/AccountModal.vue"),
  ),
  setNewPasswordModal: defineAsyncComponent(
    () => import("~/modules/account/components/sign/SetNewPassword.vue"),
  ),
  autonomicSubscriptionModal: defineAsyncComponent(
    () => import("~/modules/shared/modals/AutonomicSubscriptionModal.vue"),
  ),
  reviewModal: defineAsyncComponent(
    () => import("~/modules/shared/modals/ReviewModal.vue"),
  ),
  getContactModal: defineAsyncComponent(
    () => import("~/modules/shared/modals/GetContactModal.vue"),
  ),
  sppModal: defineAsyncComponent(
    () => import("~/modules/shared/modals/SPPModal.vue"),
  ),
};

export const useModalStore = defineStore("modalStore", {
  state: () => {
    return {
      modals: {},
    };
  },
  actions: {
    toggleModal(modal, extra = null) {
      if (undefined === this.modals[modal]) {
        this.pushModal(modal);
      }

      this.modals[modal].isActive = !this.modals[modal].isActive;
      this.modals[modal].extra = extra;

      if (Object.values(this.modals).some((modal) => modal.isActive)) {
        document.documentElement.style.overflow = "hidden";
      } else {
        document.documentElement.style.overflow = "auto";
      }
    },
    pushModal(modal) {
      this.modals[modal] = {
        isActive: false,
        extra: null,
      };
    },
  },
  getters: {
    isActiveModal: (state) => {
      return (modal) => {
        if (!state.modals.hasOwnProperty(modal)) {
          state.pushModal(modal);
        }

        return state.modals[modal].isActive;
      };
    },
    getExtra: (state) => {
      return (modal) => {
        if (!state.modals.hasOwnProperty(modal)) {
          state.pushModal(modal);
        }

        return state.modals[modal].extra;
      };
    },
    getActiveModalsName: (state) => {
      return Object.keys(state.modals).filter(
        (key) => state.modals[key].isActive,
      );
    },
  },
});
