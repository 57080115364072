export const useAdTimingStore = defineStore("adTimingStore", {
  state() {
    return {
      isAdShown: false,
    };
  },
  actions: {
    setAdShown(isShown) {
      this.isAdShown = isShown;
    },
  },
  persist: {
    storage: persistedState.cookiesWithOptions({
      maxAge: 86400,
    }),
  },
});
