<template>
  <section v-if="windowSize > 680" class="widget-helper">
    <div
      ref="mainIcon"
      class="widget-helper__main"
      :class="{ active: activeMainWidget }"
      @click="toggleMainWidget"
    >
      <div class="widget-helper__main-item">
        <img
          class="widget-helper__main-item-icon"
          src="~~/assets/icons/widget-helper-icon.svg"
          alt="widget helper"
        />
        <p class="widget-helper__main-item-text">
          {{ _T("@Connect with us") }}
        </p>
      </div>
    </div>
    <div class="widget-helper__list" :class="{ active: activeMainWidget }">
      <div
        v-for="(item, index) in allWidgets"
        :key="index"
        class="widget-helper__item"
        :class="item.img"
        @click="clickSelectedIcon(item.link)"
      >
        <div class="widget-helper__item-picture">
          <img :src="`/img/social-media/${item.img}.svg`" :alt="item.img" />
        </div>
        <span class="widget-helper__item-title">{{ _T(item.name) }}</span>
      </div>
      <div class="widget-helper__item" @click="clickPhone">
        <div class="widget-helper__item-picture">
          <img
            :src="`/img/social-media/${widgetPhone.img}.svg`"
            :alt="widgetPhone.img"
          />
        </div>
        <span class="widget-helper__item-title">{{ _T("@Back call") }}</span>
      </div>

      <svg
        class="widget-helper__list-arrow"
        width="47"
        height="41"
        viewBox="0 0 47 41"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.02934 4.2814C0.633431 3.06994 1.48699 0.7755 3.33527 0.770918L43.786 0.670625C44.8944 0.667878 45.7937 1.56712 45.7909 2.67555L45.7043 37.8062C45.7001 39.5184 43.6866 40.434 42.3935 39.3117L2.02934 4.2814Z"
          fill="white"
        />
      </svg>
    </div>
  </section>

  <WidgetHelperMob v-else />
</template>

<script setup>
import WidgetHelperMob from "~~/modules/widgetHelper/WidgetHelperMob.vue";

const widgetPhone = ref({
  img: "phone",
  name: "@Back call",
});

const allWidgets = ref([
  {
    img: "telegram",
    name: "Telegram",
    link: "https://t.me/LogicPowerBot",
  },
  {
    img: "viber",
    name: "Viber",
    link: "viber://pa?chatURI=lpofficial",
  },
  {
    img: "help-center",
    name: "@Help desk widget",
    link: "https://t.me/LogicPowerSupport_bot",
  },
]);

const mainIcon = ref(null);
const activeMainWidget = ref(false);

const { width: windowSize } = useWindowSize();

function toggleMainWidget() {
  activeMainWidget.value = !activeMainWidget.value;
}

function clickPhone() {
  activeMainWidget.value = !activeMainWidget.value;
  ringostatAPI.openCallbackForm();
}

function clickSelectedIcon(url) {
  window.open(url, "_blank");

  setTimeout(() => {
    toggleMainWidget();
  }, 400);
}
</script>

<style lang="scss" scoped>
.widget-helper {
  width: 64px;
  height: 64px;

  position: fixed;
  z-index: 1000;
  bottom: 100px;
  right: 80px;

  @include flex-container(column, center, center);

  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);

  &__main {
    cursor: pointer;
    width: 64px;
    height: 64px;

    position: relative;
    z-index: 4;

    border-radius: 50%;
    background-color: var(--color-primary-base);
    box-shadow: 0 1px 7px 0 #00000026;

    overflow: hidden;

    transition: background-color 0.4s ease-in-out;

    &:hover {
      background-color: var(--color-primary-dark);
    }

    &.active {
      background-color: var(--color-primary-dark);
    }
  }

  &__main-item {
    width: 64px;
    height: 64px;

    position: absolute;
    z-index: 5;
    top: 0;
    left: 0;

    @include flex-container(column, center, center);
  }

  &__main-item-icon {
    width: 18px;
    height: 18px;
  }

  &__main-item-text {
    @include font(10, 12, 600);
    color: white;
    text-align: center;

    padding: 2px 8px 0;
  }

  &__list {
    width: max-content;

    @include flex-container(column);

    position: absolute;
    bottom: 85px;
    right: 0;
    z-index: -1001;

    background-color: white;
    box-shadow: 0 1px 7px 0 #00000026;

    border-radius: 16px;

    padding: 16px;
    gap: 4px;

    opacity: 0;
    visibility: hidden;

    transition: opacity 0.3s ease-in-out;

    &.active {
      z-index: 1001;

      opacity: 1;
      visibility: visible;
    }
  }

  &__list-arrow {
    position: absolute;
    bottom: -26px;
    right: 26px;
  }

  &__item {
    cursor: pointer;

    @include flex-container(row, flex-start, center);

    pointer-events: all;

    padding: 4px 8px 8px;
    gap: 8px;
    border-bottom: 1px solid var(--color-sky-base);

    &:last-of-type {
      border-bottom: none;

      padding: 4px 8px;
    }
  }

  &__item-title {
    @include font(16, 19, 500);
    color: var(--color-ink-base);
  }

  &__item-picture {
    width: 40px;
    height: 40px;

    position: relative;
    @include flex-container(row, center, center);

    border-radius: 50%;
    background-color: var(--color-primary-base);

    & img {
      width: 24px;
      height: 24px;
    }
  }

  &__item-text {
    @include font(16, 19, 500);
    color: black;
    letter-spacing: -0.32px;

    transition: all 0.4s ease-in-out;
  }
}

.widget-leave-active,
.widget-enter-active {
  transition:
    transform 0.8s linear,
    opacity 0.8s linear;
}

.widget-enter-from {
  opacity: 0;
  transform: rotate(0) scale(0.5);
}

.widget-enter-to {
  opacity: 1;
  transform: rotate(1440deg) scale(1);
}

.widget-leave-to {
  transform: rotate(1440deg) scale(0.5);
  opacity: 0;
}

@keyframes firstStep {
  0% {
    transform: translateX(0);
  }
  10% {
    transform: translateX(-120%);
    opacity: 0;
  }
  30% {
    opacity: 0;
    transform: translateX(100%);
  }
  50% {
    opacity: 1;
    transform: translateX(100%);
  }
  60% {
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes secondStep {
  0% {
    opacity: 1;
    transform: translateX(100%);
  }
  10% {
    opacity: 1;
    transform: translateX(0%);
  }
  50% {
    opacity: 1;
    transform: translateX(0%);
  }
  60% {
    opacity: 0;
    transform: translateX(-120%);
  }
  100% {
    opacity: 0;
    transform: translateX(100%);
  }
}

@keyframes shadow {
  0% {
    box-shadow: 0 0 2px rgb(63, 2, 110);
  }

  50% {
    box-shadow:
      0 0 1px 8px rgba(63, 2, 110, 0.4),
      0 0 1px 18px rgba(63, 2, 110, 0.3),
      0 0 1px 36px rgba(63, 2, 110, 0.15);
  }
  100% {
    box-shadow: 0 0 2px rgba(63, 2, 110);
  }
}

@keyframes shadowSelected {
  0% {
    box-shadow: 0 0 2px rgba(63, 2, 110);
  }

  50% {
    box-shadow:
      0 0 1px 8px rgba(63, 2, 110, 0.4),
      0 0 1px 18px rgba(63, 2, 110, 0.3),
      0 0 1px 36px rgba(63, 2, 110, 0.15);
    background-color: var(--color-primary-dark);
  }
  100% {
    box-shadow: 0 0 2px rgba(63, 2, 110);
    background-color: var(--color-primary-dark);
  }
}

@keyframes hoverIcon {
  0% {
    box-shadow: 0 0 2px rgba(63, 2, 110);
  }

  50% {
    box-shadow:
      0 0 1px 2px rgba(63, 2, 110, 0.4),
      0 0 1px 6px rgba(63, 2, 110, 0.3),
      0 0 1px 12px rgba(63, 2, 110, 0.15);
    background-color: var(--color-primary-dark);
  }
  100% {
    box-shadow: 0 0 2px rgba(63, 2, 110);
  }
}
</style>
