export const pluralFormatter = (count, words) => {
  const cases = [2, 0, 1, 1, 1, 2];

  return (
    count +
    " " +
    words[
      count % 100 > 4 && count % 100 < 20 ? 2 : cases[Math.min(count % 10, 5)]
    ]
  );
};

export const priceFormatter = (amount) => {
  return amount.toString().replace(/(\d)(?=(\d{3})+(\D|$))/g, "$1 ");
};
