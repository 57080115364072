import { defineNuxtPlugin } from '#app/nuxt'
import { LazyMyLink, LazyProductLink, LazyNuxtRating, LazyNuxtStar, LazyScriptCarbonAds, LazyScriptCrisp, LazyScriptGoogleAdsense, LazyScriptGoogleMaps, LazyScriptIntercom, LazyScriptLemonSqueezy, LazyScriptLoadingIndicator, LazyScriptStripePricingTable, LazyScriptVimeoPlayer, LazyScriptYouTubePlayer, LazyRenderCacheable } from '#components'
const lazyGlobalComponents = [
  ["MyLink", LazyMyLink],
["ProductLink", LazyProductLink],
["NuxtRating", LazyNuxtRating],
["NuxtStar", LazyNuxtStar],
["ScriptCarbonAds", LazyScriptCarbonAds],
["ScriptCrisp", LazyScriptCrisp],
["ScriptGoogleAdsense", LazyScriptGoogleAdsense],
["ScriptGoogleMaps", LazyScriptGoogleMaps],
["ScriptIntercom", LazyScriptIntercom],
["ScriptLemonSqueezy", LazyScriptLemonSqueezy],
["ScriptLoadingIndicator", LazyScriptLoadingIndicator],
["ScriptStripePricingTable", LazyScriptStripePricingTable],
["ScriptVimeoPlayer", LazyScriptVimeoPlayer],
["ScriptYouTubePlayer", LazyScriptYouTubePlayer],
["RenderCacheable", LazyRenderCacheable],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
