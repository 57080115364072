import { useFavoriteProductStore } from "~/store/favoriteProductStore";
import { useAPI } from "~/uses/useMyFetch";
import { useLanguageFilterStore } from "~/store/languageFilterStore";
import languages from "~/utils/languages";
import { useCartStore } from "~/store/cartStore";

export const useUserStore = defineStore("userStore", {
  state: () => {
    return {
      user: null,
      fetchUserPromise: null,
    };
  },
  actions: {
    async fetch() {
      if (null === this.fetchUserPromise) {
        this.fetchUserPromise = useAPI("/account/me")
          .then((data) => {
            this.user = data;

            this.onUserFetched();
          })
          .finally(() => (this.fetchUserPromise = null));
      }

      return this.fetchUserPromise;
    },
    async onUserFetched() {
      const favoriteProductStore = useFavoriteProductStore();
      const languageStore = useLanguageFilterStore();

      const locale = languages.find((lang) => lang.name === this.user.locale);

      if (languageStore.getUserLanguage.name !== this.user.locale) {
        languageStore.changeLanguage(locale);
      }

      await favoriteProductStore.fetch();
    },
    async logout() {
      const favoriteProductStore = useFavoriteProductStore();
      const cartStore = useCartStore();

      await useAPI("/account/sign/out", {
        method: "POST",
      }).then(() => {
        this.user = null;
        favoriteProductStore.resetFavorites();
        cartStore.fetch();
      });
    },
    async updateAccount(data) {
      return await useAPI(
        "/account/update",
        {
          method: "POST",
          body: {
            ...data,
          },
        },
        // { isAuthorized: true },
      );
    },
    updateLocale(locale) {
      return this.updateAccount({ locale });
    },
    async userExistence(username) {
      return await useAPI(
        "/account/username",
        {
          method: "POST",
          body: {
            username,
          },
        },
        // { isAuthorized: false },
      );
    },
  },
  getters: {
    currentUser: (state) => state.user,
    userFullName: (state) => {
      if (null === state.user) {
        return;
      }

      return getFullName(state.user.name);
    },
  },
});
